import React, { Component } from "react";
import CommonHelper from "../../../helper/CommonHelper";
import SimpleReactValidator from "simple-react-validator";
import { Link, withRouter } from "react-router-dom";

export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validator = new SimpleReactValidator();
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.validator.allValid()) {
      CommonHelper.post(
        "subscribeDefence",
        { email: this.state.email },
        (status, response) => {
          this.setState({ email: "" });
        }
      );
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      this.forceUpdate();
    }
  }

  render() {
    return (
      <>
        <footer>
          <div className="container">
            <div className="footarmenu">
              <div className="item-footer">
                <h3>Aspetto</h3>
                <p className="mb-0">
                  1691 Emancipation Hwy.
                  <br />
                  Fredericksburg, VA 22401 USA
                </p>
                <span>
                  <i className="far fa-envelope"></i> info@aspettoinc.com
                </span>
              </div>
              <div className="item-footer">
                <h3>Links</h3>
                <div className="menufootaer">
                  <ul>
                    <li>
                      <Link to={"/about-us"}>About Us</Link>
                    </li>
                    <li>
                      <Link to={"/tactical"}>Tactical</Link>
                    </li>
                    <li>
                      <Link to={"/technical"}>Tech</Link>
                    </li>
                    <li>
                      <Link to={"/labs"}>Labs</Link>
                    </li>
                    <li>
                      <Link to={"/digital-media"}>Digital Media</Link>
                    </li>
                    <li>
                      <Link to={"/careers"}>Careers</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="item-footer">
                <h3>Social Media</h3>
                <ul className="social-media-links">
                  <li>
                    <a
                      href="https://www.facebook.com/aspettoinc"
                      target="blank"
                    >
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/aspetto" target="blank">
                      <i className="fab fa-instagram" aria-hidden="true" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.twitter.com/aspettoinc" target="blank">
                      <i className="fab fa-twitter" aria-hidden="true" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/aspettoinc"
                      target="blank"
                    >
                      <i className="fab fa-linkedin" aria-hidden="true" />
                    </a>
                  </li>
                  {/*<li><a href="javascript:void(0)" target="blank"><i className="fa fa-youtube" aria-hidden="true"></i></a></li>*/}
                </ul>
              </div>
            </div>
          </div>
        </footer>
        <div className="bottenfooter ">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-7">
                <p className="all">© Aspetto Inc.- All Rights Reserved</p>
              </div>
              <div className="col-md-6 col-5">
                <p type="button" className="all text-end" data-bs-toggle="modal" data-bs-target="#AccessibilityPopup">Accessibility Statement</p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="AccessibilityPopup"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Accessibility Statement</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-sm-12">
                        <h6><strong>Our Commitment to Accessibility</strong> </h6>
                        <p style={{ lineHeight: "25px" }}>
                        Aspetto is committed to ensuring digital accessibility and compliance with the standards outlined in <strong>508 of the Rehabilitation Act.</strong> We strive to create digital experiences that are accessible to everyone, including individuals with disabilities, ensuring equal access to information and functionality.</p>
                        <h6><strong> How We Evaluate Accessibility</strong></h6>
                        <p>To proactively verify, evaluate, and enhance the accessibility of our digital resources, Aspetto employs a comprehensive accessibility process, including:</p>
                        <ul className="acces_list">
                            <li>Regular accessibility assessments using automated tools and manual evaluations.</li>
                            <li>Utilization of modern Artificial Intelligence (AI) technologies to identify, analyze, and address potential accessibility concerns, helping ensure continuous compliance with federal standards.
                            </li>
                            <li>Ongoing internal training and awareness regarding accessibility guidelines and best practices.
                            </li>
                        </ul>
                        <p>Our continuous improvement approach ensures that Aspetto's digital platforms remain accessible and user-friendly to all.
                        </p>
                        <h6><strong>Feedback and Support</strong>
                        </h6>
                        <p>We welcome feedback regarding the accessibility of our digital content. If you encounter any accessibility issues or require assistance, please contact us directly:
                        </p>
                        <ul className="acces_list">
                            <li>
                            <strong>Email:</strong> <a href="mailto:info@aspettoinc.com">info@aspettoinc.com</a>
                                <p className="mb-0">(Please include "Accessibility" in the subject line.)</p>
                            </li>
                            <li><strong>Phone:</strong>  <a href="tel:(540)547-8487">(540) 547-8487 </a> </li>
                        </ul>
                        <p>We appreciate your feedback and commit to responding promptly to any accessibility-related inquiries or issues.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
